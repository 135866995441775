import React from "react"
import { useParams, Link } from 'react-router-dom';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import { outlineCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsLine } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

export const Sns_A = React.memo(({ part }) => {
  
  // console.log("SNSパーツレンダ");
  const { productId } = useParams();
  
  const snsCSS = css`
    .sns_btn {
      ${outlineCSS(part)}
    }
  `
  
  return (
    <>
      <Link to={`/product/${productId}/setting?key=Sns`}>
        <div className="menu_select_btn">各SNSのURLを設定</div>
      </Link>
      
      <div
        className={`sns_1 ${part.class_name} ${part.add_class || ""}`}
        css={snsCSS}
        id={part.add_id}
      >
        <a href="" className="line_btn sns_btn"><BsLine/></a>
        <a href="" className="twitter_btn sns_btn"><BsTwitter/></a>
        <a href="" className="facebook_btn sns_btn"><BsFacebook/></a>
        <a href="" className="instagram_btn sns_btn"><BsInstagram/></a>
        <a href="" className="youtube_btn sns_btn"><BsYoutube/></a>
        <a href="" className="tiktok_btn sns_btn"><FaTiktok/></a>
      </div>
    </>
  );
  
});