import { BiEdit, BiSitemap, BiBell, BiAlignLeft, BiShareAlt, BiLinkAlt, BiCommentDetail, BiMap } from "react-icons/bi";
import { CgMenuBoxed, CgToolbarLeft } from "react-icons/cg";
import { BsCardHeading, BsCardText, BsCardImage, 
  BsListUl, BsYoutube, BsCodeSlash, BsStarHalf, BsCreditCard2Front, BsCardList, BsTable} from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { RxIdCard, RxButton } from "react-icons/rx";
import { TfiLayoutAccordionSeparated, TfiViewList, TfiLayoutMenuSeparated } from "react-icons/tfi";
import { RiFileList2Line, RiFileUserLine} from "react-icons/ri";

export const PartTypes = [
  {name: "見出し1<br/>デフォルト", type:"Headline_A ", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-2<br/>センター", type:"Headline_A headline_1-2", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-3<br/>サブタイトルなし", type:"Headline_A headline_1-3", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-4<br/>サブなし／センター", type:"Headline_A headline_1-4", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-5<br/>ラインなし", type:"Headline_A headline_1-5", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-6<br/>ラインなし／センター", type:"Headline_A headline_1-6", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-5B<br/>サブタイトルが上", type:"Headline_A headline_1-5B", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-6B<br/>サブタイトルが上／センター", type:"Headline_A headline_1-6B", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-7<br/>タイトルのみ", type:"Headline_A headline_1-7", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し1-8<br/>タイトルのみ／センター<br/>", type:"Headline_A headline_1-8", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し2-1<br/>左線", type:"Headline_A headline_2-1", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し2-2<br/>下線", type:"Headline_A headline_2-2", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し2-3<br/>二重下線", type:"Headline_A headline_2-3", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し2-4<br/>上下線", type:"Headline_A headline_2-4", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し3-1<br/>背景色", type:"Headline_A headline_3-1", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し3-2<br/>背景色／上下線", type:"Headline_A headline_3-2", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し3-3<br/>背景色／上下点線", type:"Headline_A headline_3-3", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し3-4<br/>背景色／影", type:"Headline_A headline_3-4", img: "noimg.png", svg: CgToolbarLeft},
  // {name: "見出し3-5（背景色&影2）", type:"Headline_A headline_3-5", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し4-1<br/>ワイド", type:"Headline_A headline_4-1", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し4-2<br/>ワイド／上下線", type:"Headline_A headline_4-2", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し4-3<br/>ワイド／矢印", type:"Headline_A headline_4-3", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し4-4<br/>ワイド／三角", type:"Headline_A headline_4-4", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し5-1<br/>インライン", type:"Headline_A headline_5-1", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し5-2<br/>マーカー", type:"Headline_A headline_5-2", img: "noimg.png", svg: CgToolbarLeft},
  {name: "見出し矢印", type:"Headline_A headline_5-3", img: "noimg.png"},
  {name: "見出し矢印２", type:"Headline_A headline_5-4", img: "noimg.png"},
  // {name: "見出し矢印３", type:"Headline_A headline_5-5", img: "noimg.png"},
  {name: "見出し6-1<br/>タイトルを重ねる", type:"Headline_A headline_6-1", img: "noimg.png"},
  
  {name: "ボックス1", type:"Box_A ", img: "noimg.png", svg: BsCardHeading},
  {name: "ボックス", type:"Box_A box_1-2", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_1-3", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_2-1", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_2-2", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_2-3", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_2-4", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_2-5", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_3-1", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_3-2", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_4-1", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_4-2", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_4-3", img: "noimg.png"},
  {name: "ボックス", type:"Box_A box_4-4", img: "noimg.png"},
  
  {name: "カード", type:"Card_A ", img: "noimg.png", svg: FaRegAddressCard},
  {name: "カード", type:"Card_A card_1-2", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-3", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-4", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-5", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-6", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-7", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-8", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-9", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-10", img: "noimg.png"},
  {name: "カード", type:"Card_A card_1-11", img: "noimg.png"},
  {name: "カード", type:"Card_A card_2-1", img: "noimg.png"},
  {name: "カード", type:"Card_A card_2-2", img: "noimg.png"},
  {name: "カード（横長）", type:"Card_A card_3-1", img: "noimg.png"},
  // {name: "カード（横長）", type:"Card_A card_3-1B", img: "noimg.png"},
  {name: "カード（横長／枠あり）", type:"Card_A card_3-2", img: "noimg.png"},
  {name: "カード（横長／枠なし）", type:"Card_A card_4-1", img: "noimg.png"},
  {name: "カード（横長／枠なし／画像右）", type:"Card_A card_4-2", img: "noimg.png"},
  
  {name: "ポイント1（右）", type:"Point_A ", img: "noimg.png", svg: RxIdCard},
  {name: "ポイント1-2（左）", type:"Point_A point_1-2", img: "noimg.png", svg: RxIdCard},
  {name: "ポイント", type:"Point_A point_1-3", img: "noimg.png"},
  {name: "ポイント", type:"Point_A point_1-4", img: "noimg.png"},
  {name: "ポイント2-1（ワイド右）", type:"Point_A point_2-1", img: "noimg.png", svg: RxIdCard},
  {name: "ポイント2-2（ワイド左）", type:"Point_A point_2-2", img: "noimg.png", svg: RxIdCard},
  {name: "ポイント3-1（枠あり／右）", type:"Point_A point_3-1", img: "noimg.png", svg: RxIdCard},
  {name: "ポイント", type:"Point_A point_4-1", img: "noimg.png"},
  
  {name: "画像", type:"Image_A ", img: "noimg.png", svg: BsCardImage},
  // {name: "画像1-2", type:"Image_A image_1-2", img: "noimg.png", svg: BsCardImage},
  // {name: "画像1-3", type:"Image_A image_1-3", img: "noimg.png", svg: BsCardImage},
  {name: "画像2-1（説明付）", type:"Image_A image_2-1", img: "noimg.png", svg: BsCardImage},
  {name: "画像3-1（文字を重ねる）", type:"Image_A image_3-1", img: "noimg.png", svg: BsCardImage},
  {name: "画像3-2（文字を重ねセンター）", type:"Image_A image_3-2", img: "noimg.png", svg: BsCardImage},
  {name: "アコーディオン", type:"Accordion_A ", img: "noimg.png", svg: TfiLayoutAccordionSeparated},
  // {name: "問い合わせ", type:"Contact_A ", img: "noimg.png", svg: TfiViewList},
  {name: "ボタン1", type:"Button_A ", img: "noimg.png", svg: RxButton},
  {name: "ボタン（四角）", type:"Button_A button_1-2", img: "noimg.png"},
  {name: "ボタン（枠線）", type:"Button_A button_2-1", img: "noimg.png"},
  {name: "ボタン（枠線／四角）", type:"Button_A button_2-2", img: "noimg.png"},
  {name: "ボタン（アウトライン）", type:"Button_A button_2-3", img: "noimg.png"},
  {name: "ボタン（影）", type:"Button_A button_2-4", img: "noimg.png"},
  {name: "ボタン（影2）", type:"Button_A button_2-5", img: "noimg.png"},
  // {name: "ボタン（）", type:"Button_A button_2-6", img: "noimg.png"},
  // {name: "ボタン（）", type:"Button_A button_2-7", img: "noimg.png"},
  {name: "ボタン（マイクロコピー）", type:"Button_A button_3-1", img: "noimg.png"},
  {name: "ボタン（サブタイトル）", type:"Button_A button_4-1", img: "noimg.png"},
  // {name: "ボタン4-1（追従固定）", type:"Button_A button_4-1", img: "noimg.png", svg: RxButton},
  {name: "ナビバー1", type:"Nabvar_A ", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  // {name: "ナビバー1", type:"Nabvar_B ", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビバー1-2<br/>ボタンなし", type:"Nabvar_A navbar_1-2", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  // {name: "ナビバー2-1<br/>ロゴ画像", type:"Nabvar_A navbar_2-1", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  // {name: "ナビバー2-2<br/>ロゴ画像&ボタンなし", type:"Nabvar_A navbar_2-2", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビバー2-1<br/>タイトルのみ／センター", type:"Nabvar_A navbar_2-1", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  // {name: "ナビバー3-2<br/>ロゴ画像のみ＆センター", type:"Nabvar_A navbar_3-2", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビバー2-2<br/>メニューのみ／センター", type:"Nabvar_A navbar_2-2", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビバー3-1<br/>丸型／半透明", type:"Nabvar_A navbar_3-1", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビボタン", type:"Navbtn_A ", img: "noimg.png", svg: TfiLayoutMenuSeparated},
  {name: "ナビボタン", type:"Navbtn_A navbtn_1-2", img: "noimg.png"},
  {name: "ナビボタン", type:"Navbtn_A navbtn_1-3", img: "noimg.png"},
  {name: "ナビボタン", type:"Navbtn_A navbtn_1-4", img: "noimg.png"},
  {name: "ナビボタン", type:"Navbtn_A navbtn_1-5", img: "noimg.png"},
  {name: "ナビボタン", type:"Navbtn_A navbtn_1-6", img: "noimg.png"},
  
  {name: "メインビジュアル1", type:"MainVisual_A ", img: "noimg.png"},
  {name: "メインビジュアル1-2<br/>画像なし", type:"MainVisual_A mainvisual_1-2", img: "noimg.png"},
  {name: "メインビジュアル2-1<br/>サブタイトル上", type:"MainVisual_A mainvisual_2-1", img: "noimg.png"},
  {name: "メインビジュアル2-2<br/>サブタイトル上／画像なし", type:"MainVisual_A mainvisual_2-2", img: "noimg.png"},
  {name: "メインビジュアル3-1<br/>センター", type:"MainVisual_A mainvisual_3-1", img: "noimg.png"},
  {name: "メインビジュアル3-2<br/>センター／画像なし", type:"MainVisual_A mainvisual_3-2", img: "noimg.png"},
  {name: "メインビジュアル（センター／枠あり）", type:"MainVisual_A mainvisual_3-3", img: "noimg.png"},
  {name: "メインビジュアル（センター枠あり画像あり）", type:"MainVisual_A mainvisual_3-4", img: "noimg.png"},
  {name: "メインビジュアル4-1", type:"MainVisual_A mainvisual_4-1", img: "noimg.png"},
  
  {name: "リスト1", type:"List_A ", img: "noimg.png", svg: BsListUl},
  {name: "リスト1-2（タイトルなし）", type:"List_A list_1-2", img: "noimg.png", svg: BsListUl},
  {name: "リスト1-3（枠なし）", type:"List_A list_1-3", img: "noimg.png", svg: BsListUl},
  {name: "リスト2-1（下線）", type:"List_A list_2-1", img: "noimg.png", svg: BsListUl},
  {name: "リスト2-2（下点線）", type:"List_A list_2-2", img: "noimg.png", svg: BsListUl},
  {name: "リスト2-3（縁あり）", type:"List_A list_2-3", img: "noimg.png", svg: BsListUl},
  {name: "リスト3-1（画像あり）", type:"List_A list_3-1", img: "noimg.png", svg: BsListUl},
  {name: "フッター1（横並び）", type:"Footer_A ", img: "noimg.png", svg: BsCardList},
  // {name: "フッター1-2（ロゴ画像）", type:"Footer_A footer_1-2", img: "noimg.png", svg: BsCardList},
  {name: "フッター2-1（縦並び）", type:"Footer_A footer_2-1", img: "noimg.png", svg: BsCardList},
  
  {name: "SNSボタン", type:"Sns_A ", img: "noimg.png", svg: BiShareAlt},
  {name: "SNSボタン(枠線)", type:"Sns_A sns_1-2", img: "noimg.png", svg: BiShareAlt},
  {name: "SNSボタン2-1(ロゴのみ)", type:"Sns_A sns_2-1", img: "noimg.png", svg: BiShareAlt},
  {name: "テキスト1", type:"Text_A ", img: "noimg.png", svg: BiAlignLeft},
  {name: "テキスト2-1（枠線）", type:"Text_A text_2-1", img: "noimg.png", svg: RiFileList2Line},
  {name: "テキストリンク1", type:"Link_A ", img: "noimg.png", svg: BiLinkAlt},
  {name: "テキストリンク2-1（枠線）", type:"Link_A link_2-1", img: "noimg.png", svg: BiLinkAlt},
  {name: "吹き出し", type:"Comment_A ", img: "noimg.png", svg: BiCommentDetail},
  {name: "プライス", type:"Price_A ", img: "noimg.png", svg: RiFileUserLine},
  {name: "プライス2-1", type:"Price_A price_2-1", img: "noimg.png", svg: RiFileUserLine},
  {name: "プロフィール", type:"Profile_A ", img: "noimg.png", svg: RiFileUserLine},
  {name: "プロフィール（縦形）", type:"Profile_A profile_2-1", img: "noimg.png", svg: RiFileUserLine},
  {name: "スター評価", type:"Rating_A ", img: "noimg.png", svg: BsStarHalf},
  {name: "お知らせバー", type:"Notice_A ", img: "noimg.png", svg: BiBell},
  {name: "お知らせ", type:"Item_A ", img: "noimg.png", svg: BiBell},
  {name: "お知らせ", type:"Item_A item_1-2", img: "noimg.png", svg: BiBell},
  {name: "お知らせ", type:"Item_A item_2-1", img: "noimg.png", svg: BiBell},
  {name: "ステップ", type:"Step_A ", img: "noimg.png", svg: BiBell},
  {name: "ステップ", type:"Step_A step_2-1", img: "noimg.png", svg: BiBell},
  {name: "サイトマップ", type:"Sitemap_A sitemap_1", img: "noimg.png", svg: BiSitemap},
  {name: "HTML", type:"Html_A ", img: "noimg.png", svg: BsCodeSlash},
  {name: "YouTube", type:"Html_A youTube_1", img: "noimg.png", svg: BsYoutube},
  {name: "Googleマップ", type:"Html_A maps_1", img: "noimg.png", svg: BiMap},
  {name: "テーブル", type:"HtmlTable_A ", img: "noimg.png", svg: BsTable},
];


export const PartCategory = [
  {name: "ナビバー", type:"Nabvar"},
  {name: "メインビジュアル", type:"MainVisual"},
  {name: "フッター", type:"Footer"},
  {name: "見出し", type:"Headline"},
  {name: "ポイント", type:"Point"},
  {name: "ボックス", type:"Box"},
  {name: "カード", type:"Card"},
  {name: "プライスカード", type:"Price"},
  {name: "ボタン", type:"Button"},
  {name: "ナビボタン", type:"Navbtn"},
  {name: "プロフィール", type:"Profile"},
  {name: "リスト", type:"List"},
  {name: "吹き出し", type:"Comment"},
  {name: "スター評価", type:"Rating"},
  {name: "アコーディオン", type:"Accordion"},
  {name: "お知らせバー", type:"Notice"},
  {name: "お知らせ／項目", type:"Item"},
  {name: "ステップ", type:"Step"},
  {name: "画像", type:"Image"},
  {name: "テキスト", type:"Text"},
  {name: "リンク", type:"Link"},
  {name: "SNS", type:"Sns"},
  {name: "HTML", type:"Html"},
  {name: "YouTube", type:"youTube"},
  {name: "表", type:"Table"},
  {name: "地図", type:"maps"},
  // {name: "問い合わせ", type:"Contact"},
  {name: "サイトマップ", type:"Sitemap"},
];





// export const HeaderPartTypes = [
//   {name: "ヘッダー", type:"Header_A header_1", img: "1.jpg"},
//   {name: "ナビバー1", type:"Nabvar_A navbar_1", img: "1.jpg"},
//   {name: "ナビバー2", type:"Nabvar_A navbar_2", img: "1.jpg"},
//   {name: "ナビバー3", type:"Nabvar_A navbar_3", img: "1.jpg"},
//   {name: "ナビボタン", type:"Navbtn_A navbtn_1", img: "1.jpg"},
//   {name: "お知らせ", type:"Notice_A notice_1", img: "1.jpg"},
//   {name: "リスト", type:"List_A list_1", img: "1.jpg"},
//   {name: "フッター", type:"Footer_A footer_1", img: "1.jpg"},
//   {name: "フッター2", type:"Footer_A footer_2", img: "1.jpg"},
//   {name: "見出し1", type:"Headline_A title_1", img: "1.jpg"},
//   {name: "見出し2", type:"Headline_A title_2", img: "1.jpg"},
//   {name: "見出し3", type:"Headline_A title_3", img: "1.jpg"},
//   {name: "見出し4", type:"Headline_A title_4", img: "1.jpg"},
//   {name: "見出し5", type:"Headline_A title_5", img: "1.jpg"},
//   {name: "見出し6", type:"Headline_A title_6", img: "1.jpg"},
//   {name: "見出し7", type:"Headline_A title_7", img: "1.jpg"},
//   {name: "テキスト", type:"Text", img: "1.jpg"},
//   {name: "ボックス", type:"Box box_1", img: "1.jpg"},
//   {name: "画像", type:"Image_A image_1", img: "1.jpg"},
//   {name: "ボタン１", type:"Button_A button_1", img: "1.jpg"},
//   {name: "ボタン２", type:"Button_A button_2", img: "1.jpg"},
//   {name: "YouTube", type:"Html YouTube", img: "1.jpg"},
//   {name: "コメント", type:"Comment_A", img: "1.jpg"},
//   {name: "スター評価", type:"Rating_A ", img: "1.jpg"},
// ];